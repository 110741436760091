<template>
    <div class="birdge-step" :class="{mobile: $mobile}">
        <div class="icon">
            <img src="../../assets/images/pc/bridge-03.png" />
        </div>
        <template v-if="step === 1">
            <div class="step1-check">
                <div class="item">
                    <check-box v-model="cpd_fromChain.check" />
                    <div class="text">{{$t('checkingText', [cpd_fromChain.symbol, cpd_fromChain.netWork])}}</div>
                </div>
                <div class="item">
                    <check-box v-model="cpd_toChain.check" />
                    <div class="text">{{$t('checkingText', [cpd_toChain.symbol, cpd_toChain.netWork])}}</div>
                </div>
            </div>
            <div class="step1-button">
                <el-button @click="nextStep2" :disabled="cpd_approve_disabled">{{$t('next')}}</el-button>
            </div>
        </template>
        <template v-if="step === 2">
            <div class="step2-content">
                <div class="item">
                    <label>{{$t('transferAmount')}}</label>
                    <div class="value">{{amount}} MNC</div>
                </div>
                <div class="item">
                    <label>{{$t('transactionFee')}}</label>
                    <div class="value">{{formatValue($store.state.minFromGasAmount, 5)}} {{cpd_fromChain.symbol}}</div>
                    <div class="value">{{formatValue($store.state.minToGasAmount, 5)}} {{cpd_toChain.symbol}}</div>
                </div>
            </div>
            <div class="step2-button">
                <el-button v-if="cpd_approve" @click="transfer">{{$t('confirm')}}</el-button>
                <el-button :loading="approveLoading" :disabled="approveLoading" v-else @click="setApprove">{{$t('approve')}}</el-button>
            </div>
        </template>
        <template v-if="step === 3 || step === 4 || step === 5 || step === 6 || step === 7">
            <div class="step3-loading">
                <template v-if="!transferCompleted">
                    {{$t('loading')}}<div><span>...</span></div>
                </template>
                <template v-else>{{$t('transferCompleted')}}</template>
            </div>
            <div class="step3-progress" :class="`progress-${stepCls}`">
                <div class="from-item">
                    <div class="logo">
                        <img class="token" v-if="step === 3" :src="fromLogo" />
                        <img v-else src="../../assets/images/pc/true.png" />
                    </div>
                    <div class="bg" ref="from"></div>
                </div>
                <div class="progress"><div ref="progress" class="bg"></div></div>
                <div class="to-item">
                    <div class="logo">
                        <img class="token" v-if="!transferCompleted" :src="toLogo" />
                        <img v-else src="../../assets/images/pc/true.png" />
                    </div>
                    <div class="bg" ref="to"></div>
                </div>
            </div>
            <div class="step3-progress-text" v-if="step === 7">
                <div v-html="$t('transferedText', [amount, fromName, toName])"></div>
            </div>
            <div class="step3-progress-text" v-else>
                <div v-html="$t('transferringText', [amount, fromName, toName])"></div>
                <span>{{$t('pleaseWait')}}...</span>
            </div>
        </template>
    </div>
</template>

<script>

    import {ADDRESS, ChainIds, tokens, netWork, chainList, tokenName, ChainName} from "../../config/constant";
import socketMixin from "../../mixins/socketMixin";
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'

export default {
    name: 'Step',
    model: {
      prop: 'step',
      event: 'change'
    },
    mixins: [socketMixin],
    props:{
        step: {
            type: Number,
            default: 1
        },
        amount: {
            type: String,
            default: ''
        },
        fromChain: {
            type: String,
            default: ''
        },
        toChain: {
            type: String,
            default: ''
        },
        allowance: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            stepCls: this.step,
            approve: false,
            approveLoading: false,
            fromLogo: chainList[this.fromChain].icon,
            toLogo: chainList[this.toChain].icon,
            fromName: netWork[this.fromChain].chainName,
            toName: netWork[this.toChain].chainName,
            transferCompleted: false
        }
    },
    computed: {
        cpd_approve() {
            if (this.approve) {
                return true
            }
            if (this.allowance == 0) {
                return false
            }
            return this.allowance * 1 >= this.amount * 1
        },
        cpd_fromChain() {
            const check = (this.$store.state.fromBalance - this.$store.state.minFromGasAmount) > 0 ? 1 : 2
            console.log(check, 'check')
            return {
                netWork: netWork[this.fromChain].chainName,
                symbol: netWork[this.fromChain].nativeCurrency.symbol,
                check
            }
        },
        cpd_toChain() {
            const check = (this.$store.state.toBalance - this.$store.state.minToGasAmount) > 0 ? 1 : 2
            return {
                netWork: netWork[this.toChain].chainName,
                symbol: netWork[this.toChain].nativeCurrency.symbol,
                check
            }
        },
        cpd_approve_disabled() {
            return this.cpd_fromChain.check==2 || this.cpd_toChain.check==2
        }
    },
    watch: {
        step(v) {
            this.stepCls = v
            if (v === 6) {
                setTimeout(() => {
                    this.transferCompleted = true
                    this.$emit('change', 7)
                }, 1000 * 5)
            }
            if (v === 1) {
                this.transferCompleted = false
            }
        }
    },
    mounted() {
    },
    methods: {
        setApprove() {
            this.approveLoading = true
            const tokenContract = this.$vueweb3.contract(this.$abi.ERC20, tokens[this.fromChain]).methods
            const amount = ethers.constants.MaxUint256
            let gasPrice = this.$store.state.fromGasPrice * 1 + 2
            gasPrice = this.$web3.utils.toWei(gasPrice + '', 'Gwei')
            return tokenContract.approve(ADDRESS[this.fromChain], amount).send({from: this.$store.state.account, gasPrice: gasPrice}).then(res => {
                this.approve = true
                this.approveLoading = false
            }).catch(() => {
                this.approveLoading = false
            })
        },
        transfer() {
            console.log('step1')
            this.$emit('change', 3)
            setTimeout(()=> {
                this.$refs.from.style.width = '30%'
            }, 100)
            const bridgeContract = this.$vueweb3.contract(this.$abi.BRIDGE, ADDRESS[this.fromChain]).methods
            const cur = this.$web3.utils.padRight(this.$web3.utils.toHex(tokenName), 64)
            const address = this.$web3.utils.padLeft(this.$store.state.account, 64)
            const params = [
                cur,
                this.$web3.utils.toWei(this.amount, 'ether'), // amount:
                ChainIds[this.toChain], //destChainId:
                address, // recipient:
            ]
            // let gasPrice = this.$store.state.fromGasPrice * 1 + 2
            // gasPrice = this.$web3.utils.toWei(gasPrice + '', 'Gwei')
            this.$web3.eth.getGasPrice().then(gasPrice => {
                gasPrice = this.$web3.utils.fromWei(gasPrice, 'Gwei')
                gasPrice = gasPrice * 1 + 2
                gasPrice = this.$web3.utils.toWei(gasPrice + '', 'Gwei')
                bridgeContract.deposit(...params).send({
                    from: this.$store.state.account,
                    gasPrice
                }).then(result => {
                    this.$refs.from.style.width = '40%'
                    console.log('step2')
                    console.log(result, 'deposit')
                    const hash = result.transactionHash
                    let count = 40
                    // this.loopSocket()
                    this.loopTransactionReceipt(hash, count)
                    this.$emit('socketSend', JSON.stringify({
                        msgType: 105,
                        data: {
                            id: new Date().getTime(),
                            jsonrpc: '2.0',
                            result: {
                                address: this.$store.state.account,
                                hash,
                                chainId: this.$web3.currentProvider.chainId

                            }
                        }
                    }))
                })
            })
        },
        loopSocket() {
            this.$emit('socketSend', JSON.stringify({
                msgType: 106,
                data: {
                    id: new Date().getTime(),
                    jsonrpc: '2.0',
                    result: {
                        address: this.$store.state.account,

                    }
                }
            }))
            if (this.step < 4 ) {
                setTimeout(() => {
                    this.loopSocket()
                }, 1000 * 30)
            }
        },
        loopTransactionReceipt(hash, count) {
            console.log('step3')
            this.$web3.eth.getTransactionReceipt(hash).then((result) => {
                console.log(result, 'getTransactionReceipt')
                if (result && result.status) {
                    console.log('step4')
                    this.$refs.from.style.width = `100%`
                    setTimeout(() => {
                        this.$emit('change', 4)
                        this.$refs.progress.style.width = '50%'
                    },5000)
                    this.approve = false
                } else {
                    count = count < 90 ? count + 10 : 90
                    this.$refs.from.style.width = `${count}%`
                    setTimeout(() => {
                        this.loopTransactionReceipt(hash, count)
                    }, 3000)
                }
            })
        },
        // withdraw(params) {
        //     const bridgeContract = this.$vueweb3.contract(this.$abi.BRIDGE, ADDRESS[this.toChain]).methods
        //     console.log(params, 'params')
        //     bridgeContract.withdraw(...params).send({from: this.$store.state.account}).then(result => {
        //         console.log(result, 'withdraw')
        //     })
        // },
        nextStep2() {
            this.$emit('change', 2)
        },
        formatValue(val, decimal) {
            const value = new BigNumber(val)
            return  value.toFixed(decimal) * 1
        }
    }
}
</script>

<style lang="less">
.birdge-step {
    width: 100%;
    .icon {
        display: flex;
        justify-content: center;
        img {
            width: 30%;
        }
    }
    .step1-check {
        display: flex;
        flex-direction: column;
        padding-left: 36px;
        padding-top: 24px;
        .item {
            display: flex;
            .text {
                color: #000;
                font-family: ChalkboardSEBold;
                font-size: 18px;
                display: flex;
                align-items: center;
            }
        }
    }
    .step1-button {
        display: flex;
        justify-content: center;
        padding-top: 36px;
    }
    .step2-content {
        display: flex;
        flex-direction: column;
        padding-top: 24px;
        .item {
            width: 76%;
            display: flex;
            justify-content: space-between;
            font-size: 18px;
            color: #fff;
            margin: 0 auto;
            .value {
                font-family: ChalkboardSEBold;
            }
        }
    }
    .step2-button {
        display: flex;
        justify-content: center;
        padding-top: 36px;
    }
    .step3-loading {
        font-size: 22px;
        color: #fff;
        font-family: ChalkboardSEBold;
        line-height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
            width: 40px;
            display: flex;
        }
        span {
            width: 0px;
            overflow: hidden;
            animation: loading 0.8s infinite;
            white-space: nowrap;
        }
    }
    .step3-progress {
        width: 50%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .from-item {
            width: 64px;
            height: 64px;
            box-sizing: border-box;
            padding: 5px;
            border-radius: 50%;
            overflow: hidden;
            background-color: #fff;
            position: relative;
            .logo {
                width: 100%;
                height: 100%;
                background-color: #fff;
                overflow: hidden;
                border-radius: 50%;
                position: relative;
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                .token {
                    width: 100%;
                    height: 100%;
                }
            }
            .bg {
                position: absolute;
                width: 0%;
                height: 100%;
                background-color: #e19a2c;
                top: 0;
                left: 0;
                z-index: 0;
                transition: width 5s;
            }
        }
        .to-item {
            width: 64px;
            height: 64px;
            box-sizing: border-box;
            padding: 5px;
            border-radius: 50%;
            overflow: hidden;
            background-color: #fff;
            position: relative;
            .logo {
                width: 100%;
                height: 100%;
                background-color: #fff;
                overflow: hidden;
                border-radius: 50%;
                position: relative;
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                .token {
                    width: 100%;
                    height: 100%;
                }
            }
            .bg {
                position: absolute;
                width: 0%;
                height: 100%;
                background-color: #e19a2c;
                top: 0;
                left: 0;
                z-index: 0;
                transition: width 5s;
            }
        }
        .progress {
            flex: 1;
            background-color: #fff;
            height: 5px;
            .bg {
                height: 100%;
                width: 0%;
                background-color: #e19a2c;
                transition: width 5s;
            }
        }
        &.progress-3 {
            .from-item {
                .bg {
                    width: 0%;
                }
            }
        }
        &.progress-4 {
            .from-item {
                .bg {
                    width: 100% !important;
                }
            }
        }
        &.progress-5 {
            .from-item {
                .bg {
                    width: 100%;
                }
            }
            .progress {
                .bg {
                    width: 100% !important;
                }
            }
            .to-item {
                .bg {
                    width: 0%;
                }
            }
        }
        &.progress-6 {
            .from-item {
                .bg {
                    width: 100% !important;
                }
            }
            .progress {
                .bg {
                    width: 100% !important;
                }
            }
            .to-item {
                .bg {
                    width: 100% !important;
                }
            }
        }
        &.progress-7 {
            .from-item {
                .bg {
                    width: 100% !important;
                }
            }
            .progress {
                .bg {
                    width: 100% !important;
                }
            }
            .to-item {
                .bg {
                    width: 100% !important;
                }
            }
        }
    }
    .step3-progress-text {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        padding: 24px 0;
        color: #fff;
        font-size: 16px;
        div {
            text-align: center;
        }
        span {
            text-align: center;
        }
    }
    &.mobile {
        .icon {
            img {
                width: 26%;
                height: 26%;
            }
        }
        .step1-check {
            padding-left: 0;
            padding-top: 24px;
            .item {
                display: flex;
                .text {
                    color: #000;
                    font-family: ChalkboardSEBold;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    flex: 1;
                    word-wrap: break-word;
                    white-space: normal;
                    word-break: break-all;
                    text-align:justify;
                }
            }
        }
        .step2-content {
            .item {
                width: 100%;
                label {
                    font-size: 14px;
                }
                .value {
                    font-size: 16px;
                }
            }
        }
        .step3-progress {
            width: 80%;
        }
        .step3-progress-text {
            width: 100%;
            font-size: 14px;
        }
    }
}
@keyframes loading {
    0% {
        width: 0;
    }
    100% {
        width: 40px;
    }
}
</style>
