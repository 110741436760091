export default {
    bridge: '跨链桥',
    menu: {
        home: '首页',
        marktplace: '交易市场',
        myTools: '我的工具',
        connect: '连接钱包'
    },
    balance: '余额',
    max: '最大',
    from: '从',
    to: '到',
    transfer: '转移',
    withdraw: '提取',
    amount: '数量',
    progress: '进度',
    checkingText: '正在检查 {1} 上的 {0} 数量',
    next: '下一步',
    transferAmount: '跨链数量',
    transactionFee: '手续费',
    confirm: '确认',
    approve: '批准',
    loading: '加载中',
    transferCompleted: '转移完成',
    transferringText: '正在将 {0} MNC 从 <b>{1}</b> 转移至 <b>{2}</b>',
    pleaseWait: '这个过程可能需要几分钟, 请耐心等待',
    transferedText: '已将 {0} MNC 从 {1} 转移至 {2}',
    dialogTitle: {
        1: '正在准备您的转移请求',
        2: '等待链上确认',
        3: '您的资产正在转移中',
        4: '正在完成您的转移'
    }
}
