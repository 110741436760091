export default {
    bridge: 'Bridge',
    menu: {
        home: 'Home',
        marktplace: 'Marktplace',
        myTools: 'My Tools',
        connect: 'Connect Wallet'
    },
    balance: 'Balance',
    max: 'MAX',
    from: 'From',
    to: 'To',
    transfer: 'TRANSFER',
    withdraw: 'Withdraw',
    amount: 'Amount',
    progress: 'Progress',
    checkingText: 'Checking the amount of {0} on {1}',
    next: 'NEXT',
    transferAmount: 'Transfer Amount',
    transactionFee: 'Transaction fee',
    confirm: 'Confirm',
    approve: 'Approve',
    loading: 'LOADING',
    transferCompleted: 'Transfer Completed',
    transferringText: 'Transferring {0} MNC from <b>{1}</b> to <b>{2}</b>',
    pleaseWait: 'This may take few minutes, please wait',
    transferedText: 'Transferred {0} MNC from {1} to {2}',
    dialogTitle: {
        1: 'Preparing your transfer request',
        2: 'Awaiting confirmations on chain',
        3: 'Completing your transfer',
        4: 'Finalizing your transfer'
    }
}
