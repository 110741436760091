import Socket from '../utils/socket'
export default {
    data() {
        return {
            socket: null,
            pingSocketDelay: 15 * 1000,
            timeoutCreateSocketDelay: 0,
            socketOpened: false,
            socketTimeout: null
        }
    },
    computed: {
        cpd_websocketUrl () {
            return process.env.VUE_APP_RELAYER_URL
        }
    },
    methods: {
        /**
         * web socket 创建连接
         */
        createSocket () {
            this.socket = new Socket(this.cpd_websocketUrl)
            this.socket.createSocket({
                onopen: this.onopen,
                onmessage: this.onmessage,
                onerror: this.onerror,
                onclose: this.onclose
            })
        },
        /**
         * web socket 发送消息
         */
        socketSend (jsonStr) {
            // console.log(this.socketOpened , this.socket.socketSend(jsonStr))
            if (this.socketOpened && this.socket.socketSend(jsonStr)) {
                return true
            }
            return false
        },
        /**
         * web socket 连接递归定时 ping
         */
        pingSocket () {
            if (this.socketSend(JSON.stringify({
                "msgType": 9,
                "data": {
                    "jsonrpc": "2.0",
                    "result": {
                        "address": this.$store.state.account
                    }
                }
            }))) {
                this.pingSocketDelayed()
                return true
            }
            return false
        },
        /**
         * web socket 连接延时 ping
         */
        pingSocketDelayed () {
            this.socketTimeout = setTimeout(this.pingSocket, this.pingSocketDelay)
        },
        onopen (event) {
            this.socketOpened = true
            this.pingSocket()
        },
        /**
         * web socket on error
         */
        onerror (event) {
            console.log('webSocket onerror=========', event)
        },
        /**
         * web socket 延时重建连接
         */
        timeoutCreateSocket () {
            // 延时重建 socket，注意：必须要在 open 事件中重置为 0
            if (this.timeoutCreateSocketDelay < 15 * 1000) {
                this.timeoutCreateSocketDelay += 1000
            }
            setTimeout(this.createSocket, this.timeoutCreateSocketDelay)
        },
        /**
         * web socket on close
         */
        onclose (event) {
            // 表示异常关闭，需重连
            if (this.socketOpened) {
                this.clearSocket()
                this.socket.clearSocket()
                // g.emit(`${this.whichPage}/socketExceptionClosed`)
            } else {
                this.socket.clearSocket()
            }
        },
        /**
         * close  web socket
         */
        closeSocket () {
            if (this.socketOpened) {
                this.clearSocket()
                this.socket.closeSocket()
            }
        },
        /**
         * clear component socket state
         */
        clearSocket () {
            // this.$store.commit('trade/socketOpened', false)
            clearTimeout(this.socketTimeout)
        },
        setJsonStr(type, result) {
            const jsonData = {
                msgType: type,
                data: {
                    id: new Date().getTime(),
                    jsonrpc: '2.0',
                    result
                }
            }
            return JSON.stringify(jsonData)
        }
    }
}
