// import ETHICON_LOGO from '../assets/logo/ETH.png'
import BSCICONN_LOGO from '../assets/logo/BSC.png'
import HECO_LOGO from '../assets/logo/HECO.png'

export const local = {
    LOCALE: 'locale'
}

export const tokenName = process.env.VUE_APP_TOKEN_CODE

export const chainList = process.env.VUE_APP_NODE_ENV === 'production' ? {
    HECO: {
        name: 'HECO',
        icon: HECO_LOGO
    },
    BSC: {
        name: 'BSC',
        icon: BSCICONN_LOGO
    }
} : {
    HECO_TESTNET: {
        name: 'HECO_TESTNET',
        icon: HECO_LOGO
    },
    BSC_TESTNET: {
        name: 'BSC_TESTNET',
        icon: BSCICONN_LOGO
    }
}

export const ChainIds = {
    MAINNET: 1,
    ROPSTEN: 3,
    RINKEBY: 4,
    GÖRLI: 5,
    KOVAN: 42,
    MATIC: 137,
    MATIC_TESTNET: 80001,
    FANTOM: 250,
    FANTOM_TESTNET: 4002,
    XDAI: 100,
    BSC: 56,
    BSC_TESTNET: 97,
    ARBITRUM: 79377087078960,
    MOONBASE: 1287,
    AVALANCHE: 43114,
    FUJI: 43113,
    HECO: 128,
    HECO_TESTNET: 256,
    HARMONY: 1666600000,
    HARMONY_TESTNET: 1666700000,
    OKEX: 66,
    OKEX_TESTNET: 65
}

export const ChainName = {
    1: 'MAINNET',
    3: 'ROPSTEN',
    4: 'RINKEBY',
    5: 'GÖRLI',
    42: 'KOVAN',
    137: 'MATIC',
    80001: 'MATIC_TESTNET',
    250: 'FANTOM',
    4002: 'FANTOM_TESTNET',
    100: 'XDAI',
    56: 'BSC',
    97: 'BSC_TESTNET',
    79377087078960: 'ARBITRUM',
    1287: 'MOONBASE',
    43114: 'AVALANCHE',
    43113: 'FUJI',
    128: 'HECO',
    256: 'HECO_TESTNET',
    1666600000: 'HARMONY',
    1666700000: 'HARMONY_TESTNET',
    66: 'OKEX',
    65: 'OKEX_TESTNET',
    '0x100': 'HECO_TESTNET',
    '0x61': 'BSC_TESTNET',
    '0x3': 'ROPSTEN',
    '0x80': 'HECO',
    '0x38': 'BSC'
}

export const ADDRESS = {
    HECO_TESTNET: process.env.VUE_APP_CHAIN_A_BRIDGE,
    BSC_TESTNET: process.env.VUE_APP_CHAIN_B_BRIDGE,
    HECO: process.env.VUE_APP_CHAIN_A_BRIDGE,
    BSC: process.env.VUE_APP_CHAIN_B_BRIDGE
}

export const netWork = process.env.VUE_APP_NODE_ENV === 'production' ? {
    HECO: {
        chainId: `0x80`,
        chainName: 'HECO',
        nativeCurrency: {
            name: 'HT',
            symbol: 'HT',
            decimals: 18
        },
        rpcUrls: ["https://http-mainnet.hecochain.com"],
        blockExplorerUrls: ['https://hecoinfo.com']
    },
    BSC: {
        chainId: `0x38`,
        chainName: 'BSC',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18
        },
        rpcUrls: ["https://bsc-dataseed.binance.org"],
        blockExplorerUrls: ['https://bscscan.com']
    }
} : {
    HECO_TESTNET: {
        chainId: `0x100`,
        chainName: 'Heco Test',
        nativeCurrency: {
            name: 'HT',
            symbol: 'HT',
            decimals: 18
        },
        rpcUrls: ["https://http-testnet.hecochain.com"],
        blockExplorerUrls: ['https://testnet.hecoinfo.com']
    },
    BSC_TESTNET: {
        chainId: `0x61`,
        chainName: 'Bsc Test',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18
        },
        rpcUrls: ["https://data-seed-prebsc-2-s3.binance.org:8545"],
        blockExplorerUrls: ['https://testnet.bscscan.com']
    },
    ROPSTEN: {
        chainId: '0x3',
        chainName: 'Ropsten',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18
        },
        rpcUrls: ["https://ropsten.infura.io/v3/ee13e33dbf184e508cb29b99c808f43f"],
        blockExplorerUrls: ['https://ropsten.etherscan.io/']
    }
}

export const tokens = {
    HECO_TESTNET: process.env.VUE_APP_CHAIN_A_TOKEN,
    BSC_TESTNET: process.env.VUE_APP_CHAIN_B_TOKEN,
    HECO: process.env.VUE_APP_CHAIN_A_TOKEN,
    BSC: process.env.VUE_APP_CHAIN_B_TOKEN
}
