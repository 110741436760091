import Vue from 'vue'
import BRIDGE from './BRIDGE.json'
import ERC20 from './ERC20.json'

const abi = {
  BRIDGE,
  ERC20
}

Vue.prototype.$abi = abi
