<template>
    <div class="page-content">
        <div class="title">
            <template v-if="$i18n.locale === 'en-US'">
                <img v-if="$mobile" src="../../assets/images/mobile/content-title.png" />
                <img v-else src="../../assets/images/pc/content-title.png" />
            </template>
            <template v-else>
                <img class="zh-cn" src="../../assets/images/pc/content-title-zh.png" />
            </template>
        </div>
        <div class="balance-amount">
            <div class="logo">
                <div class="image"><img src="../../assets/logo/mnc.png" /> </div>
                <div class="name">MNC</div>
            </div>
            <div class="form">
                <div class="input">
                    <el-input type="number" v-model="amount" @input="amountChange">
                        <template slot="append">
                            <el-button class="max-button" @click="setMax">{{$t('max')}}</el-button>
                        </template>
                    </el-input>
                </div>
                <div class="balance">
                    <div class="total">{{$t('balance')}}: {{decimalValue($store.state.balance, 4)}}</div>
                </div>
            </div>
        </div>
        <div class="select-chain">
            <div class="item form">
                <div class="type">{{$t('from')}}</div>
                <div class="logo">
                    <img :src="cpd_from_chain.icon" />
                </div>
                <div class="name">
<!--                    <el-select v-model="fromChain" @change="fromChange">-->
<!--                        <el-option-->
<!--                                v-for="item in cpd_options"-->
<!--                                :key="item.name"-->
<!--                                :label="item.name"-->
<!--                                :value="item.name">-->
<!--                        </el-option>-->
<!--                    </el-select>-->
                    <c-select :value="fromChain" :options="cpd_options" @change="fromChange" />
                </div>
<!--                <div class="net-work">Network Fee: {{$store.state.fromGasPrice}} Gwei</div>-->
            </div>
            <div class="direction">
                <span></span>
                <div></div>
            </div>
            <div class="item to">
                <div class="type">{{$t('to')}}</div>
                <div class="logo">
                    <img :src="cpd_to_chain.icon" />
                </div>
                <div class="name">
<!--                    <el-select v-model="toChain" @change="toChange">-->
<!--                        <el-option-->
<!--                                v-for="item in cpd_options"-->
<!--                                :key="item.name"-->
<!--                                :label="item.name"-->
<!--                                :value="item.name">-->
<!--                        </el-option>-->
<!--                    </el-select>-->
                    <c-select :value="toChain" :options="cpd_options" @change="toChange" />
                </div>
<!--                <div class="net-work">Network Fee: {{$store.state.toGasPrice}} Gwei</div>-->
            </div>
        </div>
        <div class="submit">
            <el-button @click="transfer">{{$t('transfer')}}</el-button>
        </div>
        <div class="withdraw-list" v-if="messageQueue.length > 0">
            <template v-if="$mobile">
                <div class="list-mobile-item" v-for="(item, index) in messageQueue" :key="index">
                    <div class="item">
                        <label>{{$t('from')}} - {{$t('to')}}:</label>
                        <div>{{item.fromChainName}} - {{item.toChainName}}</div>
                    </div>
                    <div class="item">
                        <label>{{$t('amount')}}:</label>
                        <div>{{item.amount}} MNC</div>
                    </div>
                    <div class="item">
                        <label>{{$t('progress')}}:</label>
                        <div class="progress"><span :id="`history-progress-${item.depositId}`"></span></div>
                    </div>
                    <div class="item">
                        <el-button @click="historyWithdraw(item)">{{$t('withdraw')}}</el-button>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="list-header">
                    <span>{{$t('from')}}</span>
                    <span>{{$t('to')}}</span>
                    <span style="text-align: right;padding-right: 34px">{{$t('amount')}}</span>
                    <span style="text-align: center">{{$t('progress')}}</span>
                    <span></span>
                </div>
                <div class="withdraw-item" v-for="(item, index) in messageQueue" :key="index">
                    <div>{{item.fromChainName}}</div>
                    <div>{{item.toChainName}}</div>
                    <div class="amount">{{item.amount}} MNC</div>
                    <div class="progress"><div><span :id="`history-progress-${item.depositId}`"></span></div></div>
                    <div class="button"><el-button @click="historyWithdraw(item)">{{$t('withdraw')}}</el-button></div>
                </div>
            </template>
        </div>
        <el-dialog
            :title="cpd_title"
            :visible.sync="stepVisible"
            :width="$mobile ? '360px' : '640px'"
            :before-close="handleClose"
            :close-on-click-modal="false"
            :show-close="showClose"
        >
            <step
                v-model="step"
                :amount="amount"
                :step="step"
                :fromChain="fromChain"
                :toChain="toChain"
                :allowance="allowance"
                @socketSend="onSocketSend"
            />
        </el-dialog>
    </div>
</template>

<script>
import {netWork, ADDRESS, ChainName, tokens, chainList, tokenName} from '../../config/constant'
import pageMixin from "../../mixins/pageMixin"
import socketMixin from "../../mixins/socketMixin"
import step from '../../components/bridge/step'
import BigNumber from "bignumber.js";
import Web3 from "web3";

export default {
    name: 'Content',
    mixins: [pageMixin, socketMixin],
    components: {
        step
    },
    data() {
        const chainNames = Object.keys(chainList)
        return {
            fromChain: chainNames[0],
            toChain: chainNames[1],
            contrat: null,
            amount: '',
            allowance: '0',
            stepVisible: false,
            step: 1,
            messageQueue: [],
            showClose: true
        }
    },
    computed: {
        cpd_account() {
            return this.$store.state.account
        },
        cpd_chainId() {
          return this.$store.state.chainId
        },
        cpd_title() {
            const titles = {
                1: this.$t('dialogTitle.1'),
                2: this.$t('dialogTitle.1'),
                3: this.$t('dialogTitle.2'),
                4: this.$t('dialogTitle.3'),
                5: this.$t('dialogTitle.3'),
                6: this.$t('dialogTitle.3'),
                7: this.$t('dialogTitle.4')
            }
            return titles[this.step]
        },
        cpd_from_chain() {
            return chainList[this.fromChain]
        },
        cpd_to_chain() {
            return chainList[this.toChain]
        },
        cpd_options() {
            return Object.values(chainList)
        }
    },
    watch: {
        step(v) {
          if (v < 3 || v === 7) {
              this.showClose = true
          } else {
              this.showClose = false
          }
        },
        '$store.state.chainId'(v) {
            this.fromChain = ChainName[v]
        },
        '$store.state.reload'(v) {
            this.amount = ''
        },
        fromChain(newVal, oldVal) {
            this.$store.commit('setFromChain', newVal)
            this.contract = ADDRESS[newVal]
            if (newVal === this.toChain) {
                this.toChain = oldVal
            }
            if (newVal !== oldVal) {
                this.changeChain(newVal)
            }
        },
        toChain(newVal, oldVal) {
            // if (newVal === this.fromChain) {
            //     this.fromChain = oldVal
            // }
            this.$store.commit('setToChain', newVal)
        },
        cpd_account(v) {
            if (v) {
                this.changeChain(ChainName[this.$store.state.chainId])
            }
        },
        cpd_chainId(v) {
            if (ChainName[v] !== this.fromChain) {
                this.changeChain(ChainName[v])
            }
        }
    },
    created() {
        if (!sessionStorage.getItem('TO-CHAIN') || sessionStorage.getItem('TO-CHAIN') === 'undefined') {
            this.$store.commit('setToChain', this.toChain)
        }
    },
    mounted() {
        this.createSocket()
        this.initSocket()
    },
    methods: {
        initSocket() {
            if (this.socketOpened) {
                this.socketSend(this.setJsonStr(109, {
                    address: this.$store.state.account
                }))
            } else {
                setTimeout(() => {
                    this.initSocket()
                }, 1000)
            }
        },
        amountChange(v) {
            console.log(this.$store.state.balance, 111111)
            if (v >= this.$store.state.balance * 1) {
                this.amount = this.$store.state.balance
                return
            }
            let license_num = v
            license_num = license_num.replace(/[^\d]/g, '')
            if (license_num.indexOf('.') < 0 && license_num != '') {
                license_num = parseInt(license_num);
            }
            this.num = license_num
        },
        fromChange(v) {
            // sessionStorage.setItem('FROM-CHAIN', v)
            this.changeChain(v.name)
        },
        toChange(v) {
            const oldValue = this.toChain
            if (v.name === this.fromChain) {
                this.changeChain(oldValue)
            }
            this.toChain = v.name
        },
        getAllowance() {
            const tokenContract = this.$vueweb3.contract(this.$abi.ERC20, tokens[this.fromChain]).methods
            return tokenContract.allowance(this.$store.state.account, ADDRESS[this.fromChain]).call({}).then(res => {
                const amount = this.$web3.utils.fromWei(res, 'ether')
                console.log('getAllowance', amount)
                this.allowance = amount
            })
        },
        changeChain(v) {
            this.switchChain(v, () => {
                this.getAllowance()
            }, 111)
        },
        switchChain(v, callback, a) {
            this.$vueweb3.wallet_addEthereumChain({
                params: [
                    netWork[v]
                ]
            }, () => {
                this.fromChain = v
                this.$store.commit('setChainId', this.$web3.currentProvider.chainId)
                if (callback) {
                    callback()
                }
            })
        },
        setMax() {
          this.amount = this.$store.state.balance
        },
        transfer() {
            if (this.amount == 0) {
                return
            }
            this.stepVisible = true
        },
        withdraw(params, result, current) {
            this.step = 5
            const newProvider = new Web3(netWork[this.fromChain].rpcUrls[0])
            newProvider.eth.getGasPrice().then(gasPrice => {
                gasPrice = this.$web3.utils.fromWei(gasPrice, 'Gwei')
                gasPrice = gasPrice * 1 + 2
                gasPrice = this.$web3.utils.toWei(gasPrice + '', 'Gwei')
                const bridgeContract = this.$vueweb3.contract(this.$abi.BRIDGE, ADDRESS[ChainName[result.destChainId]]).methods
                bridgeContract.withdraw(...params).send({
                    from: this.$store.state.account,
                    gasPrice,
                    gas: 210000
                }).then(res => {
                    console.log(res, 'withdraw')
                    const selector = document.querySelector(`#history-progress-${result.depositId}`)
                    this.loopTransactionReceipt(res.transactionHash, selector, result.depositId, current)
                    this.socketSend(this.setJsonStr(107, {
                        address: this.$store.state.account,
                        hash: res.transactionHash,
                        // chainId: this.$web3.currentProvider.chainId
                    }))
                })
            })
        },
        loopTransactionReceipt(hash, selector, depositId, current) {
            this.$web3.eth.getTransactionReceipt(hash).then((result) => {
                if (result && result.status) {
                    if (current) {
                        this.step = 6
                    }
                    if (selector) {
                        selector.style.width = '100%'
                        setTimeout(() => {
                            const messageQueue = []
                            this.messageQueue.forEach(item => {
                                if (item.depositId !== depositId) {
                                    messageQueue.push(item)
                                }
                            })
                            this.messageQueue = messageQueue
                        }, 1000 * 6)
                    }
                    this.$store.commit('setReload', new Date().getTime())
                } else {
                    if (selector) {
                        selector.style.width = '50%'
                    }
                    setTimeout(() => {
                        this.loopTransactionReceipt(hash, selector, depositId, current)
                    }, 3000)
                }
            })
        },
        handleClose() {
            if (this.step >= 3 && this.step < 7) {
                this.stepVisible = true
                return
            }
            this.stepVisible = false
            if (this.step === 7) {
                this.step = 1
            }
        },
        onmessage(message) {
            if (message.data) {
                const data = JSON.parse(message.data)
                const msgType = data.msgType
                const result = data.data.result
                const error = data.data.error
                if (error) {
                    return
                }
                if (msgType == 106) {
                    if (this.stepVisible) {
                        this.onWithdraw(result)
                    } else {
                        this.messageQueue.push(this.formatData(result))
                    }
                }
                if (msgType == 107) {
                    this.step = 6
                }
                if (msgType === 108) {
                    this.$store.commit('setReload', new Date().getTime())
                }
                if (msgType === 109) {
                    if (result == 0) {
                        this.socketSend(this.setJsonStr(106, {
                            address: this.$store.state.account
                        }))
                        this.socketSend(this.setJsonStr(108, {
                            address: this.$store.state.account
                        }))
                    }
                    if (result == 1) {
                        this.stepVisible = true
                        this.step = 4
                    }
                    if (result == 2) {
                        this.stepVisible = true
                        this.step = 5
                    }
                }
            }
        },
        onWithdraw(result) {
            this.stepVisible = true
            this.step = 4
            this.switchChain(ChainName[result.destChainId], () => {
                const params = [
                    result.srcChainId, //srcChainId:
                    result.destChainId, //destChainId:
                    result.depositId, // depositId:
                    this.$web3.utils.padLeft(this.$store.state.account, 64), // depositor:
                    this.$web3.utils.padLeft(this.$store.state.account, 64), // recipient:
                    this.$web3.utils.padRight(this.$web3.utils.toHex(tokenName), 64), // currency:
                    result.amount, // amount:
                    '0x' + result.signature
                ]
                console.log('onWithdraw')
                this.withdraw(params, result, true)
            })
        },
        onSocketSend(jsonStr) {
            this.socketSend(jsonStr)
        },
        formatData(result) {
            result.fromChainName = ChainName[result.srcChainId]
            result.toChainName = ChainName[result.destChainId]
            result.amount = this.$web3.utils.fromWei(result.amount, 'ether')
            return result
        },
        historyWithdraw(result) {
            console.log('historyWithdraw', 11111)
            this.switchChain(ChainName[result.destChainId], () => {
                console.log('historyWithdraw')
                const params = [
                    result.srcChainId, //srcChainId:
                    result.destChainId, //destChainId:
                    result.depositId, // depositId:
                    this.$web3.utils.padLeft(this.$store.state.account, 64), // depositor:
                    this.$web3.utils.padLeft(this.$store.state.account, 64), // recipient:
                    this.$web3.utils.padRight(this.$web3.utils.toHex(tokenName), 64), // currency:
                    this.$web3.utils.toWei(result.amount), // amount:
                    '0x' + result.signature
                ]
                console.log(params, 'params')
                const selector = document.querySelector(`#history-progress-${result.depositId}`)
                if (selector) {
                    selector.style.width = '20%'
                }
                this.withdraw(params, result)
            }, 222)
        }
    }
}
</script>
