import Vue from 'vue'
import Web3 from 'web3'
import { bufferToHex } from 'ethereumjs-util'
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal"

const { ethereum } = window

if ( ethereum ) {
  ethereum.autoRefreshOnNetworkChange = false
}

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider
  }
}

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions, // required
  disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
})

class VueWeb3 {
  constructor() {
    this.queue = {
      disconnect: []
    }
    this.web3 = {}
    this.$vm = null
    this.provider = null
    this.accountsChanged = (account) => {
      console.log('accountsChanged', account)
    }
    this.chainChanged = (chainId) => {
      console.log('chainId', chainId)
    }
    if (ethereum) {
      ethereum.on('connect', (e) => {
        console.log('connect', e)
      })
      ethereum.on('disconnect', (e) => {
        console.log('disconnect', e)
      })
      ethereum.on('chainChanged', (chainId) => {
        this.chainChanged(chainId)
      })
      ethereum.on('accountsChanged', (accounts) => {
        this.accountsChanged(accounts)
      })
    }
  }
  on = (method, options) => {
    options = options || {}
    options.method = method
    return ethereum.request(options).catch(err => {
      Vue.prototype.$message.error(err.message)
    })
  }
  onQueue = (type, fn) => {
    this.queue[type].push(fn)
  }
  //
  personalSign = (account) => {
    const msg = bufferToHex(new Buffer(account, 'utf8'))
    return this.on('personal_sign',{
      params: [
        msg,
        account
      ],
      form: account
    })
  }
  // 连接钱包
  async connectWallet (vm, fns) {
    // return this.on('eth_requestAccounts') chainChanged
    this.$vm = vm
    if (fns.accountsChanged) {
      this.accountsChanged = fns.accountsChanged
      if (ethereum.selectedAddress) {
        fns.accountsChanged([ethereum.selectedAddress])
      }
    }
    if (fns.chainChanged) {
      this.chainChanged = fns.chainChanged
    }
    // console.log(web3Modal, 'web3Modal')
    this.provider = await web3Modal.connect()
    console.log(this.provider, 'this.pro')
    this.web3 = new Web3(this.provider)
    // console.log(this.web3, 'provider')
    // this.provider.on("accountsChanged", (accounts) => {
    //   if (fns.accountsChanged) {
    //     this.accountsChanged(accounts)
    //   }
    // })

    // Subscribe to chainId change
    // this.provider.on("chainChanged", (chainId) => {
    //   this.chainChanged(chainId)
    // })

    return this.provider
  }
  // 断开连接
  async disConnectWallet () {
    // console.log(this.provider, 'this.provider')
    // console.log('disConnectWallet')
    if(this.provider) {
      await this.provider.close()

      // If the cached provider is not cleared,
      // WalletConnect will default to the existing session
      // and does not allow to re-scan the QR code with a new wallet.
      // Depending on your use case you may want or want not his behavir.
      await web3Modal.clearCachedProvider();
      this.provider = null;
    }
  }
  //
  async wallet_addEthereumChain(options, callback) {
    try {
      // if (this.provider.chainId === options.params[0].chainId) {
      //   return
      // }
      options.method = 'wallet_addEthereumChain'
      await ethereum.request(options)
      checkSwitchChain(this, options.params[0].chainId, callback)
    } catch (addError) {
      console.log(addError, 'addError')
    }
    function checkSwitchChain(obj, chainId, callback) {
      if (obj.provider.chainId === chainId) {
        callback && callback()
      } else {
        setTimeout(() => {
          checkSwitchChain(obj, chainId, callback)
        }, 100)
      }
    }
  }
  async wallet_switchEthereumChain(options, callback) {
    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [options]
      })
    } catch (e) {
      callback && callback(e)
    }
    // return this.on('wallet_switchEthereumChain', options)
  }
  //
  wallet_watchAsset(params) {
    // eslint-disable-next-line no-debugger
    // debugger
    params = params || {
      type: 'ERC20',
      options: {
        address: '0x93c31DfA9D7884Ba7002a5D2dB8B4054dE18EeD6',
        symbol: 'REWARD',
        decimals: 18,
        image: 'https://foo.io/token-image.svg',
      }
    }
    return this.on('wallet_watchAsset', {
      params
    }).then(result => {
      console.log(result, 1111111)
    }).catch(err => {
      Vue.prototype.$message.error(err)
    })
  }
  //
  async getAccount() {
    const account = await this.on('eth_requestAccounts' )
    return  account
  }
  // 交易
  sendTransaction = (method, params) => {
    return ethereum.request({
      method: method || 'eth_sendTransaction',
      params: params || []
    })
  }
  // 向钱包添加代币
  walletWatchAsset = () => {
    return this.on('wallet_watchAsset', {
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: 'tokenAddress', // The address that the token is at.
          symbol: 'tokenSymbol', // A ticker symbol or shorthand, up to 5 chars.
          decimals: 'tokenDecimals', // The number of decimals in the token
          image: 'tokenImage', // A string url of the token logo
        },
      }
    })
  }
  contract = (abi, address, options) => {
    const $web3 = new Web3(ethereum)
    const contract = new $web3.eth.Contract(abi, address, options)
    return contract
  }
}

Vue.prototype.$vueweb3 = new VueWeb3()
Vue.prototype.$ethereum = ethereum || null
Vue.prototype.$web3 = ethereum ? new Web3(ethereum) : new Web3(Vue.prototype.$vueweb3.provider)
