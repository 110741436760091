import Vue from 'vue'
import App from './App.vue'
import store from './stores'
import './config/abi'
import i18n from './config/lang'
import './utils'
import './utils/web3'
import './plugins'
import './config/api'
import './components'
import router from './router'

Vue.component('MLayout', () => import('./layout/mobile'))
Vue.component('PCLayout', () => import('./layout/pc'))

// const VConsole = require('vconsole')
// new VConsole()

Vue.config.productionTip = false

new Vue({
  store,
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
