export function fetch (url = '/', query, data, method = 'get', config) {
    // eslint-disable-next-line no-debugger
    debugger
    const options = {
        url,
        method
    }
    // if (method === 'get' || query) {
    //   options.params = query || {};
    // }
    if (data) {
        switch (typeof data) {
            case 'string':
                // config = method
                options.data = data;
                break;
            case 'object':
                options.data = JSON.parse(JSON.stringify(data));
                break;
        }
        // 若为空
    } else if (typeof data === 'string' || (method && typeof method === 'object')) {
        config = method;
        method = options.method = 'get';
    }

    if (method === 'get' || query) {
        url = addUrlQuery(url, query || {})
        options.url = url;
    }
    Object.assign(options, config);
    return options;
}

export function addUrlQuery (url, key, value, unencode) {
    if (typeof url !== 'string') {
        throw new TypeError('arguments[0] must be a string');
    }
    if (!key) {
        return url;
    }
    let originPathname = url;
    let search = ''
    let hash = ''
    const searchIndex = url.indexOf('?');
    const hashIndex = url.indexOf('#');
    if (hashIndex > -1) {
        hash = url.slice(hashIndex)
        if (searchIndex > -1) {
            search = url.slice(searchIndex, hashIndex);
            originPathname = url.slice(0, searchIndex);
        } else {
            originPathname = url.slice(0, hashIndex);
        }
    } else if (searchIndex > -1) {
        search = url.slice(searchIndex)
        originPathname = url.slice(0, searchIndex);
    }
    let newSearch
    switch (typeof key) {
        case 'string':
            search += (search ? '&' : '?') + key + '=' + (unencode ? value : encodeURI(value))
            break
        case 'object':
            unencode = value
            newSearch = Object.keys(key).map(k => {
                return unencode ? k + '=' + key[k] : k + '=' + encodeURI(key[k]);
            }).join('&');
            if (newSearch) {
                search += (search ? '&' : '?') + newSearch;
            }
    }
    return originPathname + search + hash;
}

export const blockToDay = (rebateStartBlock, publishBlock) => {
    const blockAmount = rebateStartBlock - publishBlock
    let days = parseInt(blockAmount * 3000 / (1000 * 60 * 60 * 24))
    if (days < 0) days = 0
    return days
}

export const blockToTime = (amount) => {
    const blockAmount = amount
    return blockAmount * 3000
}
