import Vue from 'vue'
import pcBridge from '../pages/pc/bridge/index.vue'
import mobileBridge from '../pages/mobile/bridge/index.vue'

let mobile = false
if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    mobile = true
}
Vue.prototype.$mobile = mobile

let Bridge = null
if (!mobile) {
    Bridge = pcBridge
} else {
    Bridge = mobileBridge
}

const routes = {
    routes: [
        { path: '/', redirect: '/bridge' },
        { path: '/bridge', component: Bridge }
    ]
}

export default routes
