<template>
    <div class="content">
        <div class="line-01"><span /><span /></div>
        <div class="token-info">
            <h2>Metaminers {{$t('bridge')}}</h2>
        </div>
        <div class="line-01"><span /><span /></div>
    </div>
</template>

<script>
export default {
    name: 'Banner',
    components: {
    }
}
</script>
